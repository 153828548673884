import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Thank you soo much PartyBus for making my little one's first birthday soo very special. It was beautifully and heartfully, planned and executed. Loved every bit of it, loved every form of little Krishna that you made. Right from the birthday banner to the cake table decoration, the entrance standees, the balloons, baby Krishna, toddler Krishna , the buttery pots, the peacock..... everything was just perfect. Thank you so much for making the memorable moments soo beautiful.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Sanjeev Kumar</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The lady Mrs.Durga at party bus has got lot of talent...you just ask for and relax, Its done. We just talked on phone and she has done more than what we have expected. Everyone in my family is very happy with your work. Once again thank you for making my daughter's birthday a very memorable one</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Neelima Omkar</span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Thank you PartyBus for the amazing work. You made my son very happy on his birthday. All the hand-painted Iron Man were immaculate and impressive. I like the way you shared the ideas and concepts. Right from giving the order to suggesting ideas and getting an approval for the smallest of things, the whole working process was smooth.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Supriya Khandekar Basu</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I am happy I chose PartyBus,We loved the decorations--Durga is just Awesome!! She was very patient and took my comments in a positive way and worked on it and in the end ta-da I got the beautiful decor just as I wanted.Thanks a ton Durga.Keep up the good work!!!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Sheetal Totloorker</span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I'm very happy that you started PartyBus. You are talented and had always made the birthday parties more colorful. I'm sure that you will be very successful. Thank you for making Pranav's birthday more memorable with your decorations.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Suchithra Sridharan</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The reason we chose Party Bus was because of the soul that comes with the decoration. The output was amplified multiple times than we initially thought. Thank you Party Bus!!!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Vinothshankar Thangaraj</span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Planning a birthday party on terrace can become quite a challenge when its breezy, and so was it for us. With no walls to decorate and with no balloons agreeing to stay, it did actually become a challenge for us. But we are glad that we had Durga to our rescue. Durga, came up with these three little standees which made it a perfect birthday party. With the cake table in the center, 'Happy Birthday' standee on the right, 'Masha with it's bear' on the left and kids surrounding us, it was just perfect - we got our perfect birthday shot :) Thanks! Durga. Wishing you loads of good luck and best wishes</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Rashmi Reddy</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Good product, Good for craft</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Doreen, amazon.in customer</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;