import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const Columns = () => {
    return (
        <>
            <PageHelmet pageTitle='Columns' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Partybus Privacy and Cookie Policy'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">Partybus Privacy and Cookie Policy</h4>
                                    <p>
                                       This document is an electronic record in terms of Information Technology Act, 1999 and rules there under as applicable and the provisions
                                       pertaining to electronic records in various statutes as amended by the Information Technology Act, 1999. This electronic record is generated
                                       by a computer system and does not require any physical or digital signatures to make the terms of this policy binding.
                                    </p>
                                    <p>
                                       Thank you for visiting partybusbangalore.in. The privacy practices of this statement apply to our services available under the domain and
                                       sub-domains of www.partybusbangalore.in (the "website")
                                    </p>
                                    <p>
                                       We want you to: Feel comfortable using our website, Feel secure submitting information to us, Contact us with your questions or concerns about
                                       privacy on this site & Know that by using our website you are consenting to the collection of certain data. <b>We respect your Privacy.</b>
                                    </p>
                                    <p>
                                    This privacy policy defines the manner in which Partybus collects, uses, maintains and discloses information collected from users of the website.
                                    We recognize the importance of your privacy and understand your concerns about the security of the personal information you provide to us. This privacy
                                    policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online.
                                    PII, as described in Indian privacy law and information security, is information that can be used on its own or with other information to identify,
                                    contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of
                                    how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                                    </p>
                                    <p>
                                    At Partybus data protection is a matter of trust and your privacy is very important to us. We use your personal information only in the manner set
                                    out in this Privacy Policy. By visiting or registering with the website you agree to be bound by the terms and conditions of this Privacy Policy,
                                    expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy and that any personal information
                                    provided by you through website is provided under a lawful contract. If after you opt-in, you change your mind, you may withdraw your consent for us
                                    to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at saipartybus[at]gmail.com
                                    </p>
                                    <p><b>What personal information do we collect from the people that visit our website?</b></p>
                                    <p>
                                    When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, financial
                                    information (like account or credit card information) or other details to help you with your experience
                                    </p>
                                    <p><b>When do we collect information?</b></p>
                                    <p>We collect information from you when you register on our site, place an order, subscribe to a newsletter, respond to a survey, fill out a form,
                                    open a ticket with the customer service or enter any information on our website. Or, while you provide us with feedback on our products or services.</p>
                                    <p><b>How do we protect your information?</b></p>
                                    <p>Our website is scanned on a regular basis for security vulnerabilities in order to make your visit to our site as safe as possible. Your personal
                                    information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems,
                                    and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted. We implement a variety
                                    of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
                                    All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>
                                    <p><b>How do we use of your information?</b></p>
                                    <p>We use your personal information to facilitate the services you request. We do not sell, rent, trade or exchange any personally-identifying information
                                     of our Users. We may provide your collected information to our affiliates and service providers under contract (such as customer care, data analytics) to
                                     support the operation of the Website and our services. To the extent we use your personal information to market to you, we will provide you the ability
                                     to opt-out of such uses.</p>
                                    <p>We use your personal information to: resolve disputes; troubleshoot problems; help promote safe trading; collect fees owed; measure consumer interest
                                    in the services provided by us, inform you about online and offline offers, products, services, and updates; customize your experience; detect and protect
                                    us against error, fraud and other criminal activity; enforce our User Agreement, and in general to improve the user experience and make it comparable to
                                    other e-commerce websites; and as otherwise described to you at the time of collection. In our efforts to continually improve our product and service
                                    offerings, we collect and analyze demographic, profile and usage data about our users' activity on our Site.</p>
                                    <p>We use your personal information to improve our platform, improve the website's content and product offerings, prevent or detect fraud or abuses of
                                    our website and tailor it to meet your needs, so as to provide you with a smooth, efficient, safe and customized experience while using the Site.
                                    We may also use personally identifiable information in an aggregated form and disclose to advertisers and other third parties for other marketing and
                                    promotional purposes.</p>
                                    <p>We may also use your personal information to display contents and services that are targeted to your interests. By accepting the User Agreement and
                                    Privacy Policy, you expressly agree to receive this information. If you do not wish to receive these communications, we encourage you to opt out of by maililng
                                    saipartybus[at]gmail.com
                                    profile at any time.</p>
                                    <p><b>Internet/Cloud Service Providers:</b></p>
                                    <p>We may employ third parties services to facilitate or outsource one or more aspects of the business, product and service operations that we provide
                                    to you on the website (e.g., search technology, discussion boards, bill collection, affiliate and rewards programs, transaction payments) and therefore we
                                    may provide some of your personal information to these internal service providers. These internal service providers are subject to confidentiality agreements
                                    with us and other legal restrictions that prohibit their use of your personal information for any other purpose except to facilitate the specific outsourced
                                    service.In the event of your direct involvement with the internal service provider any additional information disclosed by you to them shall be subject
                                    to internal service provider’s own applicable privacy policy and the website shall not be responsible for the same.</p>
                                    <p><b>Payment Partner:</b></p>
                                    <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the payment card
                                    industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your
                                     purchase transaction. After that is complete, your purchase transaction inforamtion is not saved. Our payment gateway adheres to the standards set
                                     by PCI-DSS as managed by PCI Security Standards Council, which is joint effort of brands like VISA, MasterCard, American Express and Discover.</p>
                                    <p>PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers. For more insight, you may
                                    want to read terms and conditions of razorpay on https://razorpay.com</p>
                                    <p><b>Third-party disclosure:</b></p>
                                    <p>We do not include or offer third-party products or services on our website.</p>
                                    <p>We take precautions to protect your information from inadvertent disclosure a d intrusion by third parties, including using secured socket layer (SSL)
                                     encryption technology. We do not rent, sell or share your personal information and we will not disclose any of your personally identifiable information
                                     to third parties unless:</p>
                                    <p>* We have your permission</p>
                                    <p>* To provide products or services you’ve requested</p>
                                    <p>* To help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, the potential threat</p>
                                    <p>* Special circumstances such as compliance with subpoenas, court orders, requests/order, notices from legal authorities or the safety or security
                                    of any person, violations of website terms of use or to defend against legal claims law enforcement agencies requiring such disclosure.</p>
                                    <p><b>Changes to this privacy policy:</b></p>
                                    <p>If we decide to change the privacy policy, we will update this document so you will always be aware of what information we collect, how we use
                                    it and under what circumstances we disclose it. Please check our privacy policy from time to time to see if it has been updated. If at any point we
                                    decide to use personally identifiable information in a manner materially different from that stated at the time it was collected, we will notify you
                                     by way of an email.</p>
                                    <p><b>Access and Correction of personal information:</b></p>
                                    <p>You will be able to gain access to personal information held about you by us. You can also directly update your information by directly logging
                                    into your account. Where we do not agree to provide you with details of personal information we will give you reasons why. We will take reasonable
                                    steps to amend or correct your personal information to keep it accurate and up to date. Please notify us of any changes to your personal information.</p>
                                    <p><b>Age of Consent:</b></p>
                                    <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of
                                    majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                                    <p><b>Do we use ‘cookies’?</b></p>
                                    <p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow)
                                    that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.</p>
                                    <p>We use cookies to:</p>
                                    <p>• Help remember and process the items in the shopping cart.</p>
                                    <p>• Understand and save user's preferences for future visits.</p>
                                    <p>• Keep track of advertisements.</p>
                                    <p>• Compile aggregate data about site traffic and site interactions in order to offer better site experiences in the future.</p>
                                    <p>Use of cookies can be regulated though your internet browser. Your acceptance of these terms: By using this website, you signify your
                                    acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the website
                                    following the posting of changes to this policy will be deemed your acceptance of those changes. If you have any questions about this Privacy Policy,
                                    please contact us at saipartybus[at]gmail.com</p>
                                    <p><b>Contact information:</b></p>
                                    <p>If you have any queries or concerns about your personal information, or would like to make a complaint, please contact saipartybus[at]gmail.com</p>
                                    <p><b>Email:</b></p>
                                    <p>saipartybus[at]gmail.com</p>
                                    <p><b>Address:</b></p>
                                    <p>Sai Party Bus Ecom Retailers</p>
                                    <p>209, Asset Aura</p>
                                    <p>114/1 Gunjurpalaya Road</p>
                                    <p>Gunjur, Bengaluru 560087</p>
                                    <p>Karnataka, India</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Columns;