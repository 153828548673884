import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderFour extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color , headerPosition } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Partybus" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Partybus" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Partybus" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Partybus" />;
        } else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Partybus" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Partybus" />;
        }
        
        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown"><Link to="#">Cake Topper</Link>
                                    <ul className="submenu">
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-cocomelon-cake-topper-with-age-and-name" }} target="_blank" rel="noopener noreferrer">Cocomelon</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="#">Cupcake Toppers</Link>
                                    <ul className="submenu">
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-cupcake-topper-star-glitter-silver" }} target="_blank" rel="noopener noreferrer">Star Glitter - Silver</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-cupcake-topper-star-glitter-yellow" }} target="_blank" rel="noopener noreferrer">Star Glitter - Yellow</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-cupcake-topper-star-glitter-pink" }} target="_blank" rel="noopener noreferrer">Star Glitter - Pink</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-christmas-cupcake-topper-jingle-bells" }} target="_blank" rel="noopener noreferrer">Christmas - Jingle Bells</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-christmas-cupcake-topper-santa" }} target="_blank" rel="noopener noreferrer">Christmas - Santa</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-christmas-cupcake-topper-santa-and-jingle-bells" }} target="_blank" rel="noopener noreferrer">Christmas - Santa & JingleBells</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-bunny-cupcake-topper-or-bunny-1st-birthday-decor-or-bunny-birthday-party" }} target="_blank" rel="noopener noreferrer">Bunny</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="#" >B'day Banner</Link>
                                    <ul className="submenu">
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-peppa-pig-happy-birthday-banner/" }} target="_blank" rel="noopener noreferrer">Peppa Pig</Link></li>
                                        <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-toy-vehicles-happy-birthday-banner" }} target="_blank" rel="noopener noreferrer">Toy Vehicle</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="#">Craft</Link>
                                <ul className="submenu">
                                       <li><Link to={{ pathname: "https://www.mystore.in/en/product/partybus-autumn-maple-tree-leaves-mixed-colours-diy-stickers" }} target="_blank" rel="noopener noreferrer">Autumn/Maple Tree Leaves</Link></li>
                                </ul>
                                </li>
                                <li><Link to={{ pathname: "https://spark.adobe.com/page/IB4pReN54lvvQ/" }} target="_blank" rel="noopener noreferrer">Custom Decorations</Link></li>
                                <li><Link to="/about" >About</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <a className="rn-btn" href="https://www.mystore.in/en/seller/sai-party-bus-ecom-retailers">
                                <span>buy now</span>
                            </a>
                           </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFour;