import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {FaInstagram ,FaFacebookF , FaYoutube} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/PartybusBangalore/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/durga.partybus/'},
    {Social: <FaYoutube   /> , link: 'https://www.youtube.com/channel/UCFlizqZWgeynZWi_Wet-x6g/'},
]
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="2">
                                   <div className="footer-link">
                                      <h4>Policies</h4>
                                      <ul className="ft-link">
                                                    <li><Link to="/CancellationPolicy">Cancellation & Return Policy</Link></li>
                                                    <li><Link to="/PrivacyPolicy">Privacy & Cookie Policy</Link></li>
                                                    <li><Link to="/QualityPromise">Quality Promise</Link></li>
                                                    <li><Link to="/ShippingPolicy">Shipping Policy</Link></li>
                                                    <li><Link to="/TermsofService">Terms of Service</Link></li>
                                      </ul>
                                  </div>
                               </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Info</h4>
                                                <ul className="ft-link">
                                                    <li><Link to="/Blog">Blog</Link></li>
                                                    <li><Link to="/about">About</Link></li>
                                                    <li><Link to="/contact">Contact Us</Link></li>
                                                    <li><Link to={{ pathname: "https://www.amazon.in/s?k=Partybus&ref=nb_sb_noss" }} target="_blank" rel="noopener noreferrer">Partybus @ amazon.in</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/contact">saipartybus@gmail.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2021 Sai Party Bus Ecom Retailers. All Rights Reserved. Partybus(Trade name) is property of Sai Party Bus Ecom Retailers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;