// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import PartybusHome from './home/PartybusHome';

// Element Layout
import PeppaPigBanner from "./elements/PeppaPigBanner";
import About from "./elements/About";
import Contact from "./elements/Contact";
import BirthdayBannerPortfolio from "./elements/BirthdayBanner";
import CupcakeTopperPortfolio from "./elements/CupcakeTopperPortfolio";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import ordersuccess from "./elements/OrderSuccess";
import CupcakeStarMixed from "./elements/CupcakeStarMixed";
import CupcakeStarYellow from "./elements/CupcakeStarYellow";
import MulticolourBanner from "./elements/MulticolourBanner";
import AutumnLeaves from "./elements/AutumnLeaves";


// Blocks Layout

import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import CancellationPolicy from "./blocks/CancellationPolicy";
import PrivacyPolicy from "./blocks/PrivacyPolicy"
import QualityPromise from "./blocks/QualityPromise"
import ShippingPolicy from "./blocks/ShippingPolicy"
import TermsofService from "./blocks/TermsofService"


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={PartybusHome}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/PartybusHome`} component={PartybusHome}/>

                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/peppa-pig-banner`} component={PeppaPigBanner}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/birthday-banner`} component={BirthdayBannerPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/cupcake-topper`} component={CupcakeTopperPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/CupcakeStarMixed`} component={CupcakeStarMixed}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/CupcakeStarYellow`} component={CupcakeStarYellow}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/MulticolourBanner`} component={MulticolourBanner}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/AutumnLeaves`} component={AutumnLeaves}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ordersuccess`} component={ordersuccess}/>


                    {/* Blocks Elements  */}
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/CancellationPolicy`} component={CancellationPolicy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/PrivacyPolicy`} component={PrivacyPolicy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/QualityPromise`} component={QualityPromise}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ShippingPolicy`} component={ShippingPolicy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/TermsofService`} component={TermsofService}/>

                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();