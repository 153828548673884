import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.commentBox = React.createRef()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--9"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Autumn/Maple Tree Leaves - DIY Stickers</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/Mapleleaves.png" alt="Maple Leaves"/>
                                                </div>
                                                <p><a href="https://photos.app.goo.gl/MkvFa5GVS2FkmJcR9" target="_blank" rel="noopener noreferrer">Click here</a> for more product photos</p>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Partybus "Autumn/Maple Tree laeaves – Mixed colours”, Handcrafted with quality  hand made paper for "Do It Yourself" stickers</p>
                                                    <p>Partybus strives to make your prized occasions memorable by adding unique handcrafted party decoration Items that speak more than "words ever could". Your guests will love this</p>
                                                    <h4 className="title">Little Details</h4>
                                                    <ul className="liststyle">
                                                        <li>Occasion – It is suitable for kids birthday party and can integrate with forest theme decoration or Photo backdrop wall.  Ideal for Apartment community hall, Home or Hall</li>
                                                        <li>Material - Handcrafted cut of Autumn/Maple tree leaves from hand made paper sheet of mixed colours</li>
                                                        <li>Location - Ideal to place in Birthday Photo backdrop wall</li>
                                                        <li>Specifications - Each leave is of different shape and size. Total count is 60/pack</li>
                                                        <li>Protective Packaging - We provide quality inner packaging protected by congruent box to ensure it reaches you in perfect condition</li>
                                                        <li>Instructions – Simple Assembly needed & Easy to stick on wall - cellophane/cello tape support needed in the backside of each leaf. It will take 15-20 minutes to decorate a wall</li>
                                                        <li><h4 className="title">Price: ₹249</h4></li>
                                                        <li>Love Amazon buying experience</li>
                                                        <a className="rn-button-style--2 btn-solid" href="https://www.amazon.in/dp/B08NYQFDFZ?ref=myi_title_dp"> <span>Buy from Amazon</span></a>
                                                        <li>Shop directly from our website</li>
                                                        <a className="rn-button-style--2 btn-solid" href="https://rzp.io/l/OEBIJfs"> <span>Buy & Pay Secure with Razorpay</span></a>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>*Price includes GST & Shipping charges for purchases made with this website</p>
                                                    <p>*By clicking "Buy & Pay Secure with Razorpay", You agree to Terms & Conditions of this website</p>
                                                    <p>*By clicking "Buy from Amazon", You will redirected to Amazon.in for this product purchase</p>
                                                    <h4 className="title">Our Order Fulfillment Process</h4>
                                                    <ul className="liststyle">
                                                        <li>We will confirm your order in email & whatsapp</li>
                                                        <li>We usually ship in 24hrs of the receipt of order. Vist our Shipping Policy for details. Once we ship we will track every step of order shipment until it reaches you</li>
                                                        <li>Change of mind - You can cancel until we ship the order. Visit our Cancellation & Return Policy for details</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/website_video_logo.png" alt="Partybus"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Obls0-gjQ1g' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;