import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.commentBox = React.createRef()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Peppa Pig Birthday Banner</h2>
                                    <p>Keep Calm & Jump In Muddy Puddles...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/peppaproduct.png" alt="Peppa Pig Banner"/>
                                                </div>
                                                <p><a href="https://photos.app.goo.gl/7ZMTmSbvMCuepKgt5" target="_blank" rel="noopener noreferrer">Click here</a> for more product photos</p>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Partybus "Peppa Pig” Happy Birthday Banner – Unique Handcrafted with quality cardstock with vibrant colours</p>
                                                    <p>Partybus strives to make your prized occasions memorable by adding unique handcrafted party decoration Items that speak more than "words ever could". Your guests will love this</p>
                                                    <h4 className="title">Little Details</h4>
                                                    <ul className="liststyle">
                                                        <li>Occasion – It is ideal for kids birthday party with Peppa pig themed party decoration. Ideal for apartment community hall, Home or Hall</li>
                                                        <li>Material - 13 Letters for word H A P P Y B I R T H D A Y made with quality cardstock. Arcylic coloured Peppa characters are hand painted and then printed to avoid stickyness. The depicted Peppa family characters provide a vibrant & pleasant look to backdrop. The appearance of letters/banner is one sided</li>
                                                        <li>Location - Ideal to place in Birthday Party table backdrop area</li>
                                                        <li>Specifications - Each Peppa banner unit is 25-30cm X 15-17 cm in size. The banner is adjustable on the placement of Peppa characters giving you flexibility in decoration. Our products are designed to make your occasion memorable and we don’t compromize on size of item to make it cheap. Compare our specs with the rest to make a best decision for your event</li>
                                                        <li>Protective Packaging: We provide quality inner packaging protected by congruent box to ensure it reaches you in perfect condition</li>
                                                        <li>Instructions – Simple Assembly needed & Easy to stick on wall - cellephane/cello tape support needed for the thread in its edge. It will take 5-10 minutes to affix banner to wall. Handle with care. Easy to remove without any damage to wall & Reusable for your next party</li>
                                                        <li><h4 className="title">Price: ₹399</h4></li>
                                                        <li>Love Amazon buying experience</li>
                                                        <a className="rn-button-style--2 btn-solid" href="https://www.amazon.in/dp/B08XPPPTC9?ref=myi_title_dp"> <span>Buy from Amazon</span></a>
                                                        <li>Shop directly from our website</li>
                                                        <a className="rn-button-style--2 btn-solid" href="https://rzp.io/l/3GEjgijlxg"> <span>Buy & Pay Secure with Razorpay</span></a>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>*Price includes GST & Shipping charges for purchases made with this website</p>
                                                    <p>*By clicking "Buy & Pay Secure with Razorpay", You agree to Terms & Conditions of this website</p>
                                                    <p>*By clicking "Buy from Amazon", You will redirected to Amazon.in for this product purchase</p>
                                                    <h4 className="title">Our Order Fulfillment Process</h4>
                                                    <ul className="liststyle">
                                                        <li>We will confirm your order in email & whatsapp</li>
                                                        <li>We usually ship in 24hrs of the receipt of order. Vist our Shipping Policy for details. Once we ship we will track every step of order shipment until it reaches you</li>
                                                        <li>Change of mind - You can cancel until we ship the order. Visit our Cancellation & Return Policy for details</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/website_video_logo.png" alt="Partybus"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Obls0-gjQ1g' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;