import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'Story of Party bus',
        description = 'I\'am Jeyasakthi Sarangan alias Durga, Founder & Designer of Partybus brand & Sai Party Bus Ecom Retailers.',
        description1 = 'Here is my journey...when we moved to USA from Chennai in 2009, like most home makers i was mostly alone in home. I was zero in craft. Zero means really I don\'t know ABCs of CRAFT. I made use of the free time and loneliness with technology to start exploring the virtual world of blogging, web site development and learnt a lot in different CRAFTS, I should thank my husband Kannan for giving me the knowledge that is enough to explore these things. I started in CRAFT and developed my interests',
        description2 = 'One of my friend Priya Mugunthan give me a chance to do Craft designs for her son Logith first birthday. On that day I got lot & lot of appreciations from my friends and party guests, It was an unforgettable moment in my life.',
        description3 = 'I spent most of time thinking about craft and will wait for friend’s kids birthday and baby shower events to try out something. Those days were so happy for me, I completed my craft and decoration ideas of my friend’s kidoooo(Sripranav, Harshitha, Kanishk, Ashvath and Magathi) birthdays. One of the important day was being Athai(My brother\'s son) & birthday designer for Srivatsan. One day I filled a big party hall will full of baby craft for my biggest happiness in my life. It\'s my baby shower.',
        description4 = 'I am really thankful to all of my friends who gave a chance to do Birthday decorations. Experience & Appreciations made me to do Partybus as a business. Your comments and feedback are precious to me. Nothing can brighten my day when I hear a positive feedback about my craft, so let me know if you feel I deserve an appreciation. You can contact me at saipartybus@gmail.com. If you have any doubts regarding my craft here, you can ask me through mail, I will be glad to help you.'
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description1}</p>
                                            <p className="description">{description2}</p>
                                            <p className="description">{description3}</p>
                                            <p className="description">{description4}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Inception</h3>
                                                    <p>Party bus started its professional journey in 2014</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Our Journey</h3>
                                                    <p>Sai Party Bus Ecom Retailers & Partybus (Tradename) is officially registered as a company in 2019 and went live on partybusbangalore.in on Nov 26th 2020. We got onboarded into Amazon.in platform in 2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About