import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const Columns = () => {
    return (
        <>
            <PageHelmet pageTitle='Columns' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Partybus Shipping Policy'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">Partybus Shipping Policy</h4>
                                    <p>At Partybus, we are committed to ensure your order is shipped with good packaging
                                    and a reliable method to trace for assured delivery</p>
                                    <p>We ensure adequate stock is available for products that is open for ordering and
                                    most orders, Shipping starts after 24 hours of placing the order. We may take more days
                                    if there is customization in your order and will be communicated to you in 24 hours.
                                    In case, we are unable to ship after 24 hours, you will hear from us on the reason for
                                    delay (e.g. payments issue).</p>
                                    <p>Partybus will provide you with a tracking no. of the shipment and provide you with an
                                     estimated date of arrival, where possible. We ship thru reputed courier services only who
                                     have tracking facility. We ship on all days (except Sundays & Public holidays) Since we use
                                     third party courier service, We DO NOT take guarantee for deliveries on local holidays.</p>
                                    <p>The shipping geography / country is limited to India only.</p>
                                    <p>Shipping Charges for INDIA - Currently Free for all orders</p>
                                    <p>*Note for INDIA Customers : The price which you see on the Website is inclusive of GST.
                                     All charges related to Entry tax, Octroi etc (if any) needs to be borne by the customer
                                     directly at the time of delivery.</p>
                                    <p><b>Need it Faster?</b></p>
                                    <p>Please email us at saipartybus[at]gmail.com for making special requests on your order.
                                    Faster deliveries will incur additional charges to be paid by you and packages will be
                                    sent by Fedex or Blue Dart.</p>
                                    <p>For any further queries, please call us at +91 90350 19680 (8 AM - 8 PM Monday - Saturday) or
                                    email us at saipartybus[at]gmail.com</p>
                                    <p><b>Shipping FAQs</b></p>
                                    <p>Question: Can I track my order status online at Partybus</p>
                                    <p>Answer: Yes you can, once we ship your order, we will send an email communication with
                                    the tracking code with which you can use to track your shipment, on the courier company’s
                                     website</p>
                                    <p>Question: Which courier partner has Partybus partnered with for order delivery in India?</p>
                                    <p>Answer: We have partnered with ShipRocket thru which we send packages thru reputed logistics companies</p>
                                    <p>Question: Will my order reach me on time?</p>
                                    <p>Answer: Please calculate 2-3 weeks from the day you place your order with us. Exclude
                                    Sunday and National Holidays while calculating and then place your order. Your order would
                                    be generally dispatched after 24 hours of you placing it.</p>
                                    <p>Question: Do you ship internationally, which countries?</p>
                                    <p>Answer: We do not currently offer international shipping options. Special requests
                                    can be handled on case by case basis</p>
                                    <p><b>Thanks - Team Partybus</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Columns;