import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const Columns = () => {
    return (
        <>
            <PageHelmet pageTitle='Columns' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Cancellation & Return or Exchange Policy'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">Cancelling your order</h4>
                                    <p>1. When you place an order on our website, you will get an order number and an email confirmation. You can cancel from the time you placed the order up to Order shipment. You can contact us by email or whatsapp regarding your order</p>
                                    <p>2. You can also cancel your order by writing an email to saipartybus@gmail.com or call us on 91 90350 19680</p>
                                    <p>3. An order cannot be cancelled once the item has been shipped to you</p>
                                    <p>4. Once you cancel the order as per the above conditions, We will confirm the cancellation of order by email and a refund will be initiated. Refund will be processed in the same method that was used for your payment within 7 days of order cancellation. Once refund is initiated from us, we will confirm the same by email</p>
                                    <h4 className="tilte">Return or Exchange</h4>
                                    <p>How do I return my product after it has reached me?</p>
                                    <p>We strive to ensure our products leave us after quality checks and packaged in an apt way to reach you in perfect condition. We mean it and we invest in all the aspects of packaging to 3rd party courier services to ensure quality of service</p>
                                    <p>1. Party bus has a simple return policy under which you can return a product only if you receive in a damaged condition. In this scenario drop a mail to saipartybus@gmail.com with details and photos about status of the package received / defect</p>
                                    <p>2. After examination & approval by Partybus support, details of next steps will be shared with you. The next steps will contain details on status of your refund request (i.e Approved or Rejected). In case of an approved request, we will detail about next steps of the product & refund amount</p>
                                    <p>3. Approved refunds will be processed in the same method that was used for your payment within 7 days</p>
                                    <p>4. Once refund is initiated from us, we will confirm the same by email. In case further delay in getting an approved refund beyond 7 days, please contact us saipartybus@gmail.com. Decision of Partybus will be final on refunds</p>
                                    <p>5. Considering the hand crafted nature of the product and its instant utilization, Partybus does not allow return of a product once the packaging is opened and you don't like the product for any reason (color, size etc)</p>
                                    <p>6. Party bus would like to keep this fair for both customer and us, we provide as much details as possible on the product in our Product Detailing Pages(PDP) on our website and once the order is delivered to you without any damage or defective condition, Sorry, we don't entertain returns/exchanges on an order</p>
                                    <p>Thanks - Team Partybus </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Columns;